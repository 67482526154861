import React from "react";

export function ErrorPage() {
    return (
        <div className="error-page">
            <h1>404</h1>
            <h3>Not Found</h3>
            <p>The resource requested could not be found on this server!</p>
        </div>
    )
}