import React from "react";
import {ImageDownload} from '../../components'
import IphoneImage1 from "../../assets/ios-images/iPhone_1.png"
import IphoneImage2 from "../../assets/ios-images/iPhone_2.jpg"
import IphoneImage3 from "../../assets/ios-images/iPhone_3.png"
import IphoneImage4 from "../../assets/ios-images/iPhone_4.png"

export const IphoneImages = () => {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={IphoneImage2} filename="iphone-12-1"/>
                    <ImageDownload imageUrl={IphoneImage4} filename="iphone-12-2"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={IphoneImage3} filename="iphone-12-3"/>
                    <ImageDownload imageUrl={IphoneImage1} filename="iphone-12-4"/>
                </div>
            </div>
        </>
    )
}