import React from "react";
import {ImageDownload} from '../../components'
import AndroidImage1 from "../../assets/android-images/android_1.png"
import AndroidImage2 from "../../assets/android-images/android_2.jpg"
import AndroidImage3 from "../../assets/android-images/android_3.png"
import AndroidImage4 from "../../assets/android-images/android_4.png"

export function AndroidImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={AndroidImage2} filename="samsung-s21-1"/>
                    <ImageDownload imageUrl={AndroidImage4} filename="samsung-s21-2"/>
                </div>
                <div className="images-second-row">
                    <ImageDownload imageUrl={AndroidImage3} filename="samsung-s21-3"/>
                    <ImageDownload imageUrl={AndroidImage1} filename="samsung-s21-4"/>
                </div>
            </div>
        </>
    )
}